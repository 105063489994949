import { Suspense, useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { useLocation, useNavigate } from "react-router-dom"
import parser from "html-react-parser"
import { useOidcUser } from "@axa-fr/react-oidc"
import axios from "axios"
import { encode } from "base-64"
import toast from "react-hot-toast"
import { useSetRecoilState } from "recoil"

import {
  getMachine,
  getMachines,
  getFleetModelCustomerPriceWithoutDetails
} from "../../../utils/hooks"
import CustomSvgIcon from "../../Common/CustomSvgIcon"

import { dateAndTimeNow } from "../../../utils/helpers"

import Loader from "./Loader"
import Storage from "../../../utils/storage"
import Specification from "./Specification"
import Filter from "./Filter"
import Overlay from "./Overlay"
import BreadCrumb from "./BreadCrumb"
import ImageComingSoon from "../../../assets/images/image-coming-soon.png"

import {
  priceWithCurrencyHelper,
  priceUnitHelper,
  priceDebitType
} from "../../../utils/helpers"

import { useOnClickOutside } from "../../../utils/hooks"

import { machineLists } from "../MachineLists/Store"

import AddToMachineListModal from "./Modal/AddToMachineList"

export default function Machine() {
  const { t } = useTranslation()
  const filterRef = useRef()
  const location = useLocation()
  let { pathname } = location
  const navigate = useNavigate()
  const { oidcUser } = useOidcUser()
  const customer = Storage.get("customer", null, "local") ?? null

  let pathSegments = pathname.split("/")
  let machineGroupIndexId = pathSegments[4]

  //Recoil states
  const setCustomerMachineListState = useSetRecoilState(machineLists)

  // States
  const [showFilter, setShowFilter] = useState(false)
  const [showMachineListModal, setShowMachineListModal] = useState(false)
  const [machineFilterState, setMachineFilterState] = useState({
    header: null,
    state: null
  })
  const [machineModelState, setMachineModelState] = useState({
    models: [],
    priceData: null
  })
  const [machineGroupDataState, setMachineGroupDataState] = useState(null)
  const [currentModelSelected, setCurrentModelSelected] = useState(null)
  const [modelIsLoaded, setModelIsLoaded] = useState(false)

  const [customerListState, setCustomerListState] = useState(null)
  const [isAddingToList, setIsAddingToList] = useState(false)
  const [selectedMachineList, setSelectedMachineList] = useState(null)
  const [createNewMachinelist, setCreateNewMachinelist] = useState(false)

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(filterRef, () => setShowFilter(false))

  const handleSaveToMachineList = async (id) => {
    let body = []
    const listId = id

    if (!listId) {
      toast.error(t("Please select a machine list"))
      return null
    }

    // Get current machine group data
    const tempAccessories = machineGroupDataState?.acf?.accessories ?? []
    const tempServices = machineGroupDataState?.acf?.services ?? []

    // Set quantity to selected accessories and services
    tempAccessories.map((item) => {
      if (item?.selected) {
        item.quantity = item?.amount
      } else {
        item.quantity = 0
      }
      return item
    })

    tempServices.map((item) => {
      if (item?.selected) {
        item.quantity = item?.amount
      } else {
        item.quantity = 0
      }
      return item
    })

    const currentMachineGroup = machineGroupDataState
    currentMachineGroup.accessories = tempAccessories
    currentMachineGroup.services = tempServices

    const currentList = customerListState.find((item) => item?.id === listId)
    const existingMachineGroup = currentList?.data?.machines.find(
      (item) => item?.id === currentMachineGroup?.id
    )

    if (existingMachineGroup !== undefined) {
      //Updating quantity to existing model
      currentMachineGroup.quantity = existingMachineGroup.quantity + 1
      body = {
        ...currentList?.data,
        machines: currentList?.data?.machines.map((item) =>
          item?.id === currentMachineGroup?.id ? currentMachineGroup : item
        )
      }
    } else {
      //Add quantity to current model
      currentMachineGroup.quantity = 1
      body = {
        ...currentList?.data,
        machines: [...currentList?.data?.machines, currentMachineGroup]
      }
    }

    setIsAddingToList(true)

    await axios
      .put(`/api/database/customer/updateMachineList/${listId}`, {
        data: body
      })
      .then(
        (res) => {
          toast.success(`${t("Machine added to machine list")} ${body?.name}`)
          setCustomerMachineListState((prevState) => ({
            ...prevState,
            lists: prevState.lists.map((list) =>
              list.id === listId ? res.data?.rows : list
            )
          }))

          // Adding machine to machine list analytics event
          window.dataLayer.push({
            event: `HLL_adding_machine_to_machine_list`,
            company: customer?.name,
            createdAt: dateAndTimeNow(),
            userType: "customer"
          })
        },
        (err) => {
          console.log(err, "PUT /api/database/customer/updateMachineList/:id")
          toast.error(t("Something went wrong"))
        }
      )
      .finally(() => {
        setIsAddingToList(false)
        setSelectedMachineList(null)
        setShowMachineListModal(false)
      })
  }

  // Fetch current machine group
  const { data: machineGroupData } = useQuery({
    queryKey: ["machineGroup"],
    queryFn: async () => {
      const id = parseFloat(machineGroupIndexId)
      const machines = await getMachine(id)

      // Set machine group data state
      setMachineGroupDataState({
        id: machines?.id,
        title: machines?.title,
        slug: machines?.slug,
        acf: machines?.acf,
        quantity: 1
      })
      return machines
    },
    enabled: isNaN(machineGroupIndexId) === false,
    refetchOnWindowFocus: false,
    cacheTime: 60000 * 60 // 1 hour,
  })

  // Fetch all machine models
  const { isSuccess: modelsIsReady } = useQuery({
    queryKey: [`machineModels-${machineGroupData?.id}`],
    queryFn: async () => {
      let INIT_PAGE = 1
      const INIT_PER_PAGE = 100

      const params = {
        _fields: "id,title,slug,acf",
        per_page: INIT_PER_PAGE,
        page: INIT_PAGE,
        parent: parseFloat(machineGroupData?.id)
      }

      const machines = await getMachines(params)
      const meta = machines?.meta
      const models = machines?.products ?? []

      setMachineModelState((prevState) => {
        return {
          ...prevState,
          models: models
        }
      })

      setCurrentModelSelected(models[0] ?? null)

      if (meta?.totalPages > INIT_PAGE) {
        const nextPage = INIT_PAGE + 1
        const nextParams = { ...params, page: nextPage }
        const nextMachines = await getMachines(nextParams)
        const models = nextMachines?.products

        setMachineModelState((prevState) => {
          return {
            ...prevState,
            models: [...prevState.products, ...models]
          }
        })
        setCurrentModelSelected(models[0] ?? null)
        setModelIsLoaded(true)
        return models
      } else {
        setCurrentModelSelected(models[0] ?? null)
        setModelIsLoaded(true)
        return models
      }
    },
    enabled: machineGroupData?.id > 0,
    refetchOnWindowFocus: false,
    cacheTime: 60000 * 60 // 1 hour,
  })

  // Fetch current machine group price
  const { isLoading: isPriceLoading, isRefreshing: isPriceRefresing } =
    useQuery({
      queryKey: [`fleetModelCustomerPrice-${machineGroupData?.acf?.id}`],
      queryFn: async () => {
        const fleetModelPrice = await getFleetModelCustomerPriceWithoutDetails(
          customer?.id,
          [parseFloat(machineGroupData?.acf?.id)]
        )

        setMachineModelState((prevState) => {
          return {
            ...prevState,
            priceData: fleetModelPrice
          }
        })

        return fleetModelPrice ?? null
      },
      refetchOnWindowFocus: false,
      enabled: modelIsLoaded,
      cacheTime: 60000 * 60 // 1 hour,
    })

  // fetch customer machine lists
  const { refetch: customerMachineListRefresh } = useQuery({
    queryKey: ["customerMachineLists"],
    queryFn: async () => {
      const res = await axios.post("/api/database/customer/machineLists", {
        customerId: customer?.id,
        tid: encode(oidcUser?.tid),
        sub: encode(oidcUser?.sub)
      })
      const { data } = res

      setCustomerListState(data?.rows)
      return data
    },
    enabled: modelsIsReady && !!oidcUser?.tid && !!oidcUser?.sub,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true
  })

  // Redirect to first machine model
  useEffect(() => {
    if (currentModelSelected) {
      if (!pathSegments.includes(currentModelSelected?.slug) && pathname) {
        navigate(
          `/${t("customer")}/${t("machine")}/${t("group")}/${
            machineGroupData?.id
          }/${machineGroupData?.slug}/${currentModelSelected?.slug}`,
          {
            replace: true
          }
        )
      }
    }
  }, [
    currentModelSelected,
    machineGroupData,
    navigate,
    pathSegments,
    pathname,
    t
  ])

  const documents = currentModelSelected?.acf?.documents ?? []
  const videos = currentModelSelected?.acf?.videos ?? []
  const featureImage = currentModelSelected?.acf?.image
    ? currentModelSelected?.acf?.image
    : machineGroupData?.acf?.image ?? null

  let contractPriceDebit = priceDebitType(
    machineModelState?.priceData?.debitType
  )

  let customerMachineLists = customerListState?.filter(
    (list) =>
      list?.data?.private === false ||
      list?.data?.userid === `${encode(oidcUser?.tid)}${encode(oidcUser?.sub)}`
  )

  return (
    <>
      <AddToMachineListModal
        loading={isAddingToList}
        customerMachineListRefresh={customerMachineListRefresh}
        createNewMachinelist={createNewMachinelist}
        setCreateNewMachinelist={setCreateNewMachinelist}
        selectedMachineList={selectedMachineList}
        setSelectedMachineList={setSelectedMachineList}
        customerMachineLists={customerMachineLists}
        show={showMachineListModal}
        setShow={setShowMachineListModal}
        handleSaveToMachineList={handleSaveToMachineList}
        machineGroupDataState={machineGroupDataState}
      />
      <BreadCrumb machineGroupData={machineGroupData} />
      {showFilter && <Overlay />}
      <div ref={filterRef}>
        <Filter
          machineGroupData={machineGroupData}
          machineFilterState={machineFilterState}
          machineModelState={machineModelState}
          currentModelSelected={currentModelSelected}
          setCurrentModelSelected={setCurrentModelSelected}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      </div>
      <div className="single-machine-mode bg-white mb-5">
        <Suspense fallback={<Loader limit={1} />}>
          <article>
            {modelsIsReady && (
              <header className="single-machine-model__header py-5 px-0 px-lg-4">
                <div className="container-fluid container-fluid-max-width">
                  <div className="row d-flex align-items-center py-0 py-lg-4">
                    <div className="col-12 col-lg-6">
                      {featureImage ? (
                        <figure className="single-machine-model__image-wrapper mb-4 mb-lg-0">
                          <div>
                            <img
                              src={featureImage}
                              alt={
                                currentModelSelected?.title?.rendered
                                  ? parser(machineGroupData?.title?.rendered)
                                  : ""
                              }
                            />
                          </div>
                        </figure>
                      ) : (
                        <figure className="single-machine-model__image-wrapper mb-4 mb-lg-0">
                          <div className="d-flex align-items-center">
                            <img
                              src={ImageComingSoon}
                              alt={t("Image coming soon")}
                              className="logo"
                            />
                          </div>
                        </figure>
                      )}
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="rounded bg-lighter p-4">
                        <div className="machine">
                          <small className="machine__sku d-block text-muted text-small mb-1">
                            {`#${machineGroupData?.acf?.article_number}`}
                          </small>
                          <h1 className="machine__title">
                            {machineGroupData?.title?.rendered &&
                              parser(machineGroupData?.title?.rendered)}
                          </h1>
                          {machineGroupData?.acf?.description &&
                            machineGroupData?.acf?.description.length > 0 && (
                              <div className="machine__description mb-3">
                                {machineGroupData?.acf?.description &&
                                  parser(machineGroupData?.acf?.description)}
                              </div>
                            )}

                          <div className="machine__price mb-3">
                            {!machineModelState?.priceData ||
                            isPriceLoading ||
                            isPriceRefresing ? (
                              <div className="d-flex align-items-center justify-content-start">
                                <small className="d-block ml-2">{`${t(
                                  "Loading price"
                                )}...`}</small>
                              </div>
                            ) : (
                              <>
                                {machineModelState?.priceData?.rentalDiscount
                                  ?.discountPrice > 0 ? (
                                  `${priceWithCurrencyHelper(
                                    machineModelState?.priceData?.rentalDiscount
                                      ?.discountPrice
                                  )} ${priceUnitHelper(
                                    machineModelState?.priceData?.priceUnit
                                  )}`
                                ) : (
                                  `${priceWithCurrencyHelper(
                                    machineModelState?.priceData?.rentalPrice
                                  )} ${priceUnitHelper(
                                    machineModelState?.priceData?.priceUnit
                                  )}`
                                )}
                                {contractPriceDebit && (
                                  <small className="d-block text-muted mb-2">
                                    {contractPriceDebit}
                                  </small>
                                )}

                                {machineModelState?.priceData?.rentalPrice && (
                                  <div className="d-flex align-items-center justify-content-start">
                                    <span className="mr-2">
                                      <i className="fe fe-info text-primary" />
                                    </span>
                                    <small>
                                      {t(
                                        "All prices are your contract prices, excluding VAT, excluding insurance and may differ from the final invoice."
                                      )}
                                    </small>
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          {currentModelSelected && (
                            <div className="machine__model-select-wrapper mt-4">
                              <button
                                className="btn btn-white btn-block d-flex align-items-center justi-content-between py-3 px-4"
                                onClick={() => {
                                  setShowFilter(!showFilter)
                                  setMachineFilterState((prevState) => {
                                    return {
                                      ...prevState,
                                      header: t("Models"),
                                      state: "models"
                                    }
                                  })
                                }}>
                                <span className="mr-auto">
                                  {t("Showing model")}
                                </span>
                                <span className="ml-auto">
                                  <div className="d-flex align-items-center">
                                    {currentModelSelected?.title?.rendered && (
                                      <span className="font-weight-bold">
                                        {parser(
                                          currentModelSelected?.title?.rendered
                                        )}
                                      </span>
                                    )}
                                    <div className="svg-icon-wrapper text-primary ml-3">
                                      <CustomSvgIcon
                                        name="chevron-right"
                                        width={16}
                                        height={16}
                                      />
                                    </div>
                                  </div>
                                </span>
                              </button>
                            </div>
                          )}
                          {machineGroupData?.acf?.accessories &&
                            machineGroupData?.acf?.accessories.length > 0 && (
                              <div className="machine__model-select-wrapper mt-2">
                                <button
                                  className="btn btn-white btn-block d-flex align-items-center justify-content-between py-3 px-4"
                                  onClick={() => {
                                    setShowFilter(!showFilter)
                                    setMachineFilterState((prevState) => {
                                      return {
                                        ...prevState,
                                        header: t("Accessories"),
                                        state: "accessories"
                                      }
                                    })
                                  }}>
                                  <span className="mr-auto">
                                    {t("Accessories")}
                                  </span>
                                  <span className="ml-auto">
                                    <div className="d-flex align-items-center">
                                      {`${parseFloat(
                                        machineGroupData?.acf?.accessories
                                          .length
                                      )} ${t("pc")}`}
                                      <div className="svg-icon-wrapper text-primary ml-3">
                                        <CustomSvgIcon
                                          name="chevron-right"
                                          width={16}
                                          height={16}
                                        />
                                      </div>
                                    </div>
                                  </span>
                                </button>
                              </div>
                            )}
                          <div className="machine__model-select-wrapper d-flex align-items-center justify-content-between mt-2">
                            <div className="w-100 position-relative">
                              <button
                                disabled={
                                  isAddingToList || !machineGroupDataState
                                }
                                onClick={() => {
                                  setShowMachineListModal(true)
                                }}
                                className="btn btn-primary btn-block d-flex align-items-center justify-content-between py-3 px-4">
                                <span className="mr-auto">
                                  {t("Save to machine list")}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            )}
            {modelsIsReady && (
              <main className="single-machine-model__main py-5 px-0 px-lg-4 border-top">
                <div className="single-machine-model__specifications py-0 py-lg-5">
                  <div className="container-fluid container-fluid-max-width">
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                        <h2>{t("Technical information")}</h2>

                        {currentModelSelected?.acf?.specifications &&
                        currentModelSelected?.acf?.specifications.length > 0 ? (
                          <div className="technical-specification-grid border rounded">
                            <div className="technical-specification-grid__item bg-lighter d-flex align-items-center justify-content-between py-3 px-4">
                              <span className="mr-auto">{t("Model")}</span>
                              <span className="ml-auto">
                                <div className="d-flex align-items-center">
                                  {currentModelSelected?.title?.rendered && (
                                    <span className="font-weight-bold">
                                      {parser(
                                        currentModelSelected?.title?.rendered
                                      )}
                                    </span>
                                  )}
                                </div>
                              </span>
                            </div>
                            {currentModelSelected?.acf?.specifications.map(
                              (item, i) => (
                                <Specification key={i} {...item} />
                              )
                            )}
                          </div>
                        ) : (
                          <div className="technical-specification-grid__item d-flex align-items-center justify-content-between">
                            {t("No specifications available")}
                          </div>
                        )}
                      </div>

                      <div className="col-12 col-lg-6">
                        <h2>{t("Document")}</h2>
                        {documents && documents?.length === 0 ? (
                          <div className="technical-specification-grid rounded">
                            <div className="technical-specification-grid__item d-flex align-items-center justify-content-between">
                              {t("No documents available")}
                            </div>
                          </div>
                        ) : (
                          <div className="technical-specification-grid rounded">
                            {documents?.map((item, i) => (
                              <div
                                className="technical-specification-grid__item bg-lighter border-bottom d-flex align-items-center justify-content-between py-3 px-4"
                                key={i}>
                                <span className="mr-auto">
                                  <div className="d-flex align-items-center">
                                    <div className="svg-icon-wrapper text-black mr-3">
                                      <CustomSvgIcon
                                        name="file"
                                        width={16}
                                        height={16}
                                      />
                                    </div>
                                    <span>{item?.name}</span>
                                  </div>
                                </span>
                                <span className="ml-auto">
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    rel="noreferrer">
                                    <div className="d-flex align-items-center">
                                      <span className="text-primary">
                                        {t("Show")}
                                      </span>
                                    </div>
                                  </a>
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                        <h2 className="mt-4">{t("Movies")}</h2>
                        {videos && videos?.length === 0 ? (
                          <div className="technical-specification-grid rounded">
                            <div className="technical-specification-grid__item d-flex align-items-center justify-content-between">
                              {t("No movies available")}
                            </div>
                          </div>
                        ) : (
                          <div className="technical-specification-grid rounded">
                            {videos?.map((item, i) => (
                              <div
                                videosclassName="technical-specification-grid__item bg-lighter border-bottom d-flex align-items-center justify-content-between py-3 px-4"
                                key={i}>
                                <span className="mr-auto">{item?.name}</span>
                                <span className="ml-auto">
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    rel="noreferrer">
                                    <div className="d-flex align-items-center">
                                      <span className="text-primary">
                                        {t("Show")}
                                      </span>
                                    </div>
                                  </a>
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            )}
          </article>
        </Suspense>
      </div>
    </>
  )
}
